
<template>
  <form>
    <div
      class="form-group required-field"
      :class="[{ 'has-error': vErrors.first('message'), 'has-success' : !vErrors.first('message') && value['message'] }]"
    >
      <span
        v-show="vErrors.has('message')"
        class="help-block"
      >
        {{ vErrors.first('message') }}
      </span>
    </div>
  </form>
</template>

<script>
    import FormModalValidation from '../../../../share/form/FormModalValidation'

    export default {
        mixins: [
            FormModalValidation
        ],
        props: {
            value: { type: Object, required: true }
        }
    }
</script>
