export default {
  data () {
    return {
      commentTypesList: [
        {
          type: 'public',
          value: 4
        },
        {
          type: 'internal',
          value: 2
        },
        {
          type: 'private',
          value: 1
        },
        {
          type: 'api',
          value: 8
        }
      ]
    }
  },
  methods: {
    getAllowedCommentTypes (actions, actionNamePrefix, client = null) {
      let commentsTypes = []
      this.commentTypesList.forEach(comment => {
        actions.forEach(action => {
          let commentType = {}
          let actionNameCommon = actionNamePrefix + '_' + comment.type
          let actionNameClient = actionNamePrefix + '_' + comment.type + '__' + client

          if (action === actionNameCommon || action === actionNameClient) {
            let commentLabel = action === actionNameClient ? this.$t('pages.taskDetails.comments.modal.' + comment.type + client) : this.$t('pages.taskDetails.comments.modal.' + comment.type)
            commentType.action = action
            commentType.type = comment.type
            commentType.typeInt = comment.value
            commentType.client = action === actionNameClient ? client : null
            commentType.label = commentLabel
            commentsTypes.push(commentType)
          }
        })
      })
      return commentsTypes
    }
  }
}
