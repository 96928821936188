<template>
  <base-form-modal
    :show="show"
    :title="title"
    :ok-text="okText"
    :cancel-text="cancelText"
    :remember-model-after-close="true"
    @show="open"
    @close="handleClose"
    @beforeSubmit="bool => (beforeSubmitTrigger = bool)"
  >
    <div class="form-group">
      <select-type
        v-model="model['type']"
        name="commentType"
        :label="$t('pages.taskDetails.comments.modal.selectLabel')"
        :required="true"
        :options="commentTypesOptions"
        :empty-first="false"
      />
    </div>
    <edit-form-modal
      ref="form"
      v-model="model"
      :is-before-submit="beforeSubmitTrigger"
    />
  </base-form-modal>
</template>

<script>
import BaseFormModal from "../../../../share/modal/BaseFormModal"
import FormModalMixin from "../../../../share/modal/FormModalMixin"
import EditFormModal from "../form/EditComment"
import api from "../../../../../api"
import ValidatorReset from "../../../../share/mixins/ValidatorReset"
import SelectType from "../../../../share/form/type/SelectType"
import WebStorage from "../../../../share/WebStorage"
import CommentMixin from '../CommentMixin'
import _ from "lodash"

export default {
  components: {
    SelectType,
    BaseFormModal,
    EditFormModal
  },
  mixins: [
    FormModalMixin,
    ValidatorReset,
    CommentMixin
  ],
  props: {
    comment: { type: Object, default: () => {} },
    service: { type: String, required: true, default: "" },
    taskType: { type: String, default: "main" }
  },
  data() {
    return {
      model: {},
      sending: false,
      title: this.$t("pages.taskDetails.comments.modal.editModalTitle"),
      commentType: null,
      commentTypes: [],
      commentTypesOptions: [],
      trans: {
        private: {
          value: 1,
          label: this.$t("pages.taskDetails.comments.modal.private"),
          type: "private"
        },
        internal: {
          value: 2,
          label: this.$t("pages.taskDetails.comments.modal.internal"),
          type: "internal"
        },
        public: {
          value: 4,
          label: this.$t("pages.taskDetails.comments.modal.public"),
          type: "public"
        }
      }
    }
  },

  mounted() {
    this.getCommentTypes(this.model)
  },
  methods: {
    getCommentTypes(comment) {
      const actions = WebStorage.getSecurityActions()

      if (this.$isWithClients(this.service)) {
        let actionNamePrefix = this.taskType === 'main' ? 'post_comment__main' : 'post_comment__appraisal'
        let client = this.$route.meta.client
        this.commentTypes = this.getAllowedCommentTypes(actions[this.service], actionNamePrefix, client)
        this.commentTypesOptions = this.commentTypes.map((comment) => {
          comment.value = comment.typeInt
          return comment
        })
      } else {
        this.commentTypesOptions = actions[this.service]
          .filter(action =>
            this.taskType === "main"
              ? action.includes("post_comment_main")
              : action.includes("post_comment_appraisal")
          )
          .map(action => {
            return this.trans[action.split("_")[3]]
          })
      }
    },
    submitForm() {
      let id =
        this.taskType === "appraisal"
          ? this.$route.params.appraisalTaskId
          : this.$route.params.id
      let comment = this.createDataToSend()
      this.$isWithClients(this.service)
        ? this.submitCommentClients(id, comment)
        : this.submitCommentBase(id, comment)
    },
    parseModelType(incomingType) {
      return Object.values(this.trans).find(
        type => type.value.toString() === incomingType.toString()
      ).type
    },
    submitCommentBase(id, comment) {
      if (this.sending === true) {
        this.$notify({
          type: "info",
          title: this.$t("pages.taskDetails.comments.modal.commentSending"),
          text: ""
        })
        return
      }
      this.sending = true
      api
        .request(this.service, "put", `/tasks/comments/${comment.id}`, comment)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$t(
              "pages.taskDetails.comments.modal.commentVisibilityChanged"
            )
          })
          this.$emit("success", {
            commentId: this.model.id,
            type: this.model.type
          })
          this.$emit("close")
          this.model = {}
          this.sending = false
        })
        .catch(() => {
          this.sending = false
          this.$notify({
            type: "error",
            title: this.$t('error.default'),
            text: this.$t(
              "pages.taskDetails.comments.modal.commentEditSendingError"
            )
          })
        })
    },
    submitCommentClients(id, comment) {
      if (this.sending === true) {
        this.$notify({
          type: "info",
          title: this.$t('pages.taskDetails.comments.modal.commentSending'),
          text: ""
        })
        return
      }
      this.sending = true
      let data = {
        taskId: id,
        content: this.model.content
      }

      data[`${this.taskType}`] = true

      let commentTypeList = this.commentTypes.filter(comment => comment.typeInt === parseInt(this.model.type))
      let commentTypeElement = _.isEmpty(commentTypeList) ? {} : commentTypeList[0]

      if (commentTypeElement !== null && commentTypeElement.client !== null) {
        data['client'] = commentTypeElement.client
        data[commentTypeElement.type] = true
      } else {
        data[`${this.parseModelType(this.model.type)}`] = true
      }

      api
        .request(this.service, "put", `/comments/${this.model.id}`, data)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$t(
              "pages.taskDetails.comments.modal.commentEditSuccessful"
            )
          })
          this.$emit("success", {
            commentId: this.model.id,
            type: this.model.type
          })
          this.$emit("close")
          this.model = {}
          this.sending = false
        })
        .catch(() => {
          this.sending = false
          this.$notify({
            type: "error",
            title: this.$t('error.default'),
            text: this.$t('pages.taskDetails.comments.modal.commentEditSendingError')
          })
        })
    },
    createDataToSend() {
      let dataToSend = {
        type: this.model.type,
        content: this.model.content,
        id: this.model.id
      }
      return dataToSend
    },
    open() {
      this.model = _.cloneDeep(this.comment)
      this.toggleLoading()
    },
    handleClose() {
      this.$emit("close")
    }
  }
}
</script>
