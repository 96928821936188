<template>
  <div>
    <div class="comment-header">
      <p>
        <span class="comment-icon-wrapper">
          <i 
            class="comment-icon" 
            :class="[`comment-icon_${CommentTypes[comment.type]}`, CommentIcons[comment.type]]"
          />
        </span>
          {{ comment.createdBy }} |
          {{ comment.taskNumber }} | 
          {{ formatDate(comment.createdAt) }} {{ comment.transitionName ? ' | ' + comment.transitionName : ''}} | 
          {{ CommentNames[comment.type] }} | {{$t('pages.taskDetails.comments.commentItemHeader.owner')}}:
      </p>
      <span
        v-if="canEditComment"
        class="comment-actions"
        @click="() => $emit('editComment', comment)"
      >
        <i class="feather-edit"/>
      </span>
    </div>
    <div class="comment-body">
      <div class="comment-avatar">
        <img
          ref="avatarImage"
          class="img-responsive"
          :src="convertImg(avatar)"
        >
      </div>
      <div class="comment-content" :class="{'comment-content-transition': comment.transitionName}">
        <template v-if="comment.reason">
          <p v-for="(reason, index) in reasons" :key="`reason-${index}`">{{ reason }}
          </p>
        </template>
        <p class="comment-content-wrapping">{{ comment.content }}</p>
      </div>
    </div>
    <!-- <div class="comment-actions">
                  <ul>
                  <li>Akcja 1</li>
                  <li>Akcja 2 komentarza</li>
                  </ul>
            </div> -->
  </div>
</template>

<script>
  export default {
    props: {
      comment: {
        type: Object, default: () => {
        }
      },
      avatar: {
        type: String, default () {
          return ''
        }
      },
      service: {type: String, required: true}
    },
    data() {
      return {
        CommentTypes: {
          1: 'private',
          2: 'internal',
          4: 'public',
          8: 'api'
        },
        CommentIcons: {
          1: 'feather-lock',
          2: 'feather-repeat',
          4: 'feather-users',
          8: 'feather-repeat'
        },
        CommentNames: {
          1: 'Prywatny',
          2: 'Wewnętrzny',
          4: 'Publiczny',
          8: 'Tuw'
        },
      }
    },
    computed: {
      canEditComment () {
        return this.$store.state.base.securityActions[this.service].includes('put_comment')
      },
      reasons () {
        if (!this.comment.hasOwnProperty('reason')) {
          return []
        }
        return typeof this.comment.reason === 'string' ?
          this.comment.reason.split(',').filter(el => el) :
          this.comment.reason.filter(el => el)
      }
    },
    methods: {
      // @param dateVal 2017-12-01 14:49:37
      formatDate (dateVal) {
        const date = new Date(dateVal)
        return date.toLocaleDateString('pl-PL') + ' ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
      },
      convertImg (avatar) {
        if (avatar !== undefined) {
          return `data:image-png;base64,${avatar}`
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comment-content-transition {
    background-color: #ff9281
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
  }

  .comment-icon {
    font-size: 1.6rem;
    font-weight: 600;

    &_private {
      color: #e41a1a;
    }

    &_public {
      color: green;
    }

    &_internal {
      color: orange;
    }

    &-wrapper {
      margin-right: 1rem;
    }
  }

  .comment-content-wrapping {
    white-space: pre-wrap;
  }
</style>
