<template>
  <div class="row">
    <div class=" col-sm-offset-2 col-sm-8">
      <ul class="comment-list">
        <!-- Single Comment -->
        <li
          v-for="(comment, commentId) in sortedComments"
          :key="commentId"
          :class="`comment-item comment-item-${taskType(comment)}`"
        >
          <Item
            :comment="comment"
            :avatar="avatars[comment.contractor]"
            @editComment="editComment"
            :service="service"
          />
        </li>
        <edit-comment-modal
          v-if="openCommentModal"
          :comment="editCommentDetails"
          :form-data="editCommentDetails"
          :show="openCommentModal"
          :avatar="avatar"
          :service="service"
          :task-type="taskType(editCommentDetails)"
          @close="openCommentModal = false"
          @success="updateCommentType"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import api from '../../../../api'
import WebStorage from '../../../share/WebStorage'
import Item from './Item'
import moment from 'moment'
import EditCommentModal from './modal/EditCommentModal'
// import CommentItem from './ReactCommentItem'

export default {
  components: {
    Item,
    EditCommentModal
    // 'comment-item': CommentItem
  },
  props: {
    comments: { type: Array, default () { return [] } },
    avatar: { type: String, default () { return '' } },
    service: { type: String, required: true }
  },
  data () {
    return {
      avatars: {},
      openCommentModal: false,
      editCommentDetails: {},
      commentTypeIcon: {
        public: 'fa-volume-up',
        internal: 'fa-volume-down',
        private: 'fa-volume-off'
      },
      trans: {
        private: { value: 1, label: this.$t('pages.taskDetails.comments.modal.private'), type: 'private' },
        internal: { value: 2, label: this.$t('pages.taskDetails.comments.modal.internal'), type: 'internal' },
        public: { value: 4, label: this.$t('pages.taskDetails.comments.modal.public'), type: 'public' }
      },
      transBLS: {
        private: { value: 'private', label: this.$t('pages.taskDetails.comments.modal.private'), type: 'private' },
        internal: { value: 'internal', label: this.$t('pages.taskDetails.comments.modal.internal'), type: 'internal' },
        public: { value: 'public', label:  this.$t('pages.taskDetails.comments.modal.public'), type: 'public' }
      }
    }
  },
  computed: {
    sortedComments () {
      let comments = this.comments
      return comments.sort(function (a, b) {
        return moment(a.createdAt) - moment(b.createdAt)
      }).reverse()
    }
    // events () {
    //   return {
    //     commentEditDetails: `comment:edit:details`
    //   }
    // },
  },
  /* TODO: do sprawdzenia do czego showImage jest potrzebne
   * watch: {
    comments: function (val) {
      this.showImage('')
    }
  }, */
  created () {
    this.avatars = WebStorage.getAvatars()
    if (Object.keys(this.avatars).length === 0) {
      this.loadAvatars()
    }
  },
  // mounted () {
  //   this.$events.on(this.events.commentEditDetails, this.commentEditDetailsDemo)
  // },
  methods: {
      updateCommentType (ev) {
        this.$emit('updateCommentType', ev)
      },
    convertImg (symbol) {
      let file = this.avatar
      if (this.avatars[symbol] !== undefined) {
        file = this.avatars[symbol]
      }
      return `data:image-png;base64,${file}`
    },
    loadAvatars () {
      api.request('core', 'get', 'contractors/avatars')
        .then((response) => {
          WebStorage.setAvatars(response.data)
          this.avatars = response.data
        })
        .catch((e) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można pobrać avatarów'
          })
        })
    },
    taskType (comment) {
      if (this.comments.length !== 0 && comment && comment.taskSupportedClass) {
        if (comment.taskSupportedClass.toLowerCase().includes('main')) {
          return 'main'
        } else if (comment.taskSupportedClass.toLowerCase().includes('appraisal')) {
          return 'appraisal'
        }
      }
    },
    editComment(comment) {
        // comment.type = this.getCommentType(comment, this.service)
        this.editCommentDetails = comment
        this.openCommentModal = true
    },
    /* showImage (file) {
      let img = this.$refs.avatarImage
      img.src = `data:image-png;base64,${file}`
    } */
  }
}
</script>

<style scoped>
.comment-actions {
  float: right;
}
.comment-actions li {
  display: inline-block;
  margin: 0 5px 0 5px;
  cursor: pointer;
}
.comment-actions:hover {
  color: #0095ff;
}
</style>
